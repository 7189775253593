.title {
  font-size: 24px;
  font-weight: bold;
}

.centre {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
